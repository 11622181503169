@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'typeface-inter';

@keyframes typing {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
  100% { content: '....'; }
}

.typing::after {
  content: '';
  display: inline-block;
  animation: typing 1s steps(4, end) infinite;
}